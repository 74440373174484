import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Table, Tooltip, Badge } from "antd";
import { GeminiBillingCost, GetServerDailyCost, OpenAPIBillingCost, StabilityDiffusionCost } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { ServerDailyCost, BillingCostOpenAI, StabilityDiffusion, BillingCostGemini } = useSelector((state) => state.counter);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(OpenAPIBillingCost({ dispatch }));
    dispatch(GetServerDailyCost({ dispatch }));
    dispatch(GeminiBillingCost({ dispatch }));
    dispatch(StabilityDiffusionCost({ dispatch }));
  }, [dispatch]);

  const awsCost = ServerDailyCost?.Amount || 0;
  const awsUnit = ServerDailyCost?.Unit || "USD";

  const openAIAmount = BillingCostOpenAI?.total_cost || 0;
  const openAIUnit = BillingCostOpenAI?.Unit || "USD";

  const stabilityDiffusionCost = StabilityDiffusion?.total_cost_in_dollar || 0;
  const stabilityDiffusionUnit = StabilityDiffusion?.Unit || "USD";

  const geminiData = BillingCostGemini?.total_cost || 0;
  const geminiUnit = BillingCostGemini?.Unit || "USD";

  const costData = [
    {
      title: "AWS",
      cost: `${awsCost} ${awsUnit}`,
      amount: "200$",
      tooltip: "Click to view AWS data",
    },
    {
      title: "Open AI",
      cost: `${openAIAmount} ${openAIUnit}`,
      amount: "50$",
      tooltip: "Click to view Open AI data",
    },
    {
      title: "Stability Diffusion",
      cost: `${stabilityDiffusionCost} ${stabilityDiffusionUnit}`,
      amount: "70$",
      tooltip: "Click to view Stability Diffusion data",
    },
    {
      title: "Gemini AI",
      cost: `${geminiData} ${geminiUnit}`,
      amount: "60$",
      tooltip: "Click to view Gemini AI data",
    },
    {
      title: "Hugging Face",
      cost: "20$ USD",
      amount: "9$",
      tooltip: "Click to view Hugging Face data",
    },
  ];

  const onCardClick = (item) => {
    navigate("/billingstatistics");
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Reference Number",
      dataIndex: "refNumber",
      key: "refNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          <Badge status={status === "Active" ? "success" : "error"} text={status} />
        </span>
      ),
    },

  ];

  const dataSource = [
    {
      key: "1",
      firstName: "Test",
      lastName: "Test",
      email: "Test@gmail.com",
      refNumber: "!@#765387873%^scbvsaha",
      status: "Active",
    },
    {
      key: "2",
      firstName: "Test",
      lastName: "Test",
      email: "Test@gmail.com",
      refNumber: "!@#765387873%^scbvsaha",
      status: "Inactive",
    },
    {
      key: "3",
      srNo: "3",
      firstName: "Test",
      lastName: "Test",
      email: "Test@gmail.com",
      refNumber: "!@#765387873%^scbvsaha",
      status: "Inactive",
    },
  ];

  return (
    <>
      <div className="billing-container">
        <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", marginBottom: "16px", color: "#17c1e8" }}>Admin Dashboard</h1>

        <Row gutter={[16, 16]} justify="center">
          {costData.map((item, index) => (
            <Col xs={24} sm={12} md={8} lg={4} key={index}>
              <Tooltip title={item.tooltip}>
                <Card
                  title={item.title}
                  style={{
                    height: "200px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  bodyStyle={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  onClick={() => onCardClick(item)}
                >
                  <p>
                    Your threshold amount is:
                    <span style={{ fontWeight: "bold" }}>{item.amount}</span>
                  </p>
                  <p>
                    Cost: <span style={{ fontWeight: "bold" }}>{item.cost}</span>
                  </p>
                </Card>
              </Tooltip>
            </Col>
          ))}
        </Row>

        <Row style={{ marginTop: "20px" }} justify="center">
          <Col xs={24}>
            <div style={{ overflowX: "auto" }}>
              <div>
                <p style={{ fontWeight: "600" }}>No of Active Users: 3</p>
              </div>
              <Table dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: "max-content" }} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
