import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Typography, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PhoneInput } from "react-international-phone";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../../App.css";

import "react-international-phone/style.css";
import {
  AddMinting,
  AddMultiplePost,
  AddMultiplePostTest,
  AddPost,
  AddPostTest,
  GetProfile,
  GetUserWalletBalance,
  GetUserWalletDetails,
  UpdateProfile,
  UploadProfilePicture,
} from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import avatar from "../../Utils/Images/userpic.png";
import { GetDecryptEmailformat, GetDecryptEmailformatccssscscs, GetDecryptPrivateKey, GetDecryptReferenceNumberformat, GetUserId } from "../../Components/Common/Common";

const { Title } = Typography;

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileDetails, isLoading, updateUserImage, getUserWalletDetails, InstagramProfileData, userPrivateKey, walletBalanceUser } = useSelector((state) => state?.counter);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    country: "",
    city: "",
    reference_number: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [isProfilePictureUpdating, setIsProfilePictureUpdating] = useState(false);
  const [privatekeyUser, setPrivatekeyUser] = useState("");

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  //   // Add your update logic here, e.g., dispatch an action
  // };

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  // const onFinish = async (values) => {
  //
  //   const { image } = values;
  //   const imageFile = image[0]?.originFileObj;
  //
  //   let obj = {
  //     email: GetDecryptEmailformat(),
  //     reference_number: GetDecryptReferenceNumberformat(),
  //     user_id: GetDecryptEmailformat(),
  //   };

  //   return;
  //   if (imageFile) {
  //     try {
  //       await dispatch(
  //         UploadProfilePicture({
  //           email,
  //           referenceNumber,
  //           imageFile,
  //           obj,
  //           dispatch,
  //         })
  //       );
  //       handleCancel();
  //     } catch (error) {
  //       console.error("Upload failed:", error);
  //     }
  //   } else {
  //     console.error("No image file selected.");
  //   }
  // };

  const SelectCountry = (val) => {
    setCountry(val);
  };

  const SelectRegion = (val) => {
    setRegion(val);
  };

  useEffect(() => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
      user_id: GetDecryptEmailformat(),
    };

    dispatch(GetProfile({ obj, dispatch }));
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      email: profileDetails[0]?.email ? profileDetails[0]?.email : formData?.email,
      phone: profileDetails[0]?.phone ? profileDetails[0]?.phone : formData?.phone,
      country: profileDetails[0]?.country ? profileDetails[0]?.country : formData?.country,
      city: profileDetails[0]?.city ? profileDetails[0]?.city : formData?.city,
      reference_number: profileDetails[0]?.reference_number ? profileDetails[0]?.reference_number : formData?.reference_number,
    });
  }, [profileDetails[0]]);

  const OnFinish = (values) => {
    const file = values?.image?.[0]?.originFileObj || null;
    if (!file && !isProfilePictureUpdating) {
      delete values.image;
    }


    let obj = values;

    const callback = () => {
      if (file) {
        let objforupdatepic = {
          email: GetDecryptEmailformat(),
          reference_number: GetDecryptReferenceNumberformat(),
          image: file,
        };
        dispatch(UploadProfilePicture({ objforupdatepic, dispatch, obj }));
      }
    };

    dispatch(UpdateProfile({ obj, navigate, dispatch, callback }));
    setShowForm(false);
  };

  const Toggle = () => {
    setIsProfilePictureUpdating(false);
    setShowForm(true);
  };

  const OnBackButtonClick = () => {
    setShowForm(false);
  };

  useEffect(() => {
    let UserPrivateKeyData = GetDecryptEmailformatccssscscs(userPrivateKey);
    if (UserPrivateKeyData) {
      setPrivatekeyUser(UserPrivateKeyData);
    }
  }, []);

  // useEffect(() => {

  //   const formData = {
  //     instaUsername: "arex",
  //     fbUsername: "",
  //     linkedinUsername: "",
  //     xUsername: "",
  //     instaFollowers: "40",
  //     fbFollowers: "0",
  //     linkedinFollowers: "0",
  //     xFollowers: "0",
  //   };

  //   const postdetails = {
  //     userTokenID: 23,
  //     postUrl: "https://www.twitter.com/help/20340361208222571212",
  //     platformType: "twitter",
  //     multiple: false,
  //   };

  //   const postUrl = [
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //     "https://www.instagram.com/help/20340361208222571212",
  //   ];

  //   let IgPosts = postUrl?.map((ele) => {
  //    // console.log(ele, "eleeee");
  //     return (ele.split(".")[1]);
  //    // console.log(instaurls, "urlllll");
  //   });

  //   const postMultipledetails = {
  //     userTokenID: 23,
  //     postUrl: postUrl,
  //     platformType: IgPosts,
  //     multiple: true,
  //   };

  //   console.log(IgPosts, "Posts");

  //   // dispatch(AddMinting({ formData }));
  // dispatch(AddPost({ postdetails }));
  //   // dispatch(AddMultiplePost({ postMultipledetails }));
  // }, []);
  // const profilePictureUrl = profileDetails?.[0]?.profile_picture_url;
  // console.log(profilePictureUrl, "urlllllllllllllsssss");

  // useEffect(() => {
  //   const formData = {
  //         instaUsername: "sunny7775444",
  //         fbUsername: "",
  //         linkedinUsername: "",
  //         xUsername: "",
  //         instaFollowers: "40",
  //         fbFollowers: "0",
  //         linkedinFollowers: "0",
  //         xFollowers: "0",
  //         walletAddress:"0x8d94f0bfc6d0cce61ed2752b0d09cbc3fd20e38f"
  //       };
  //       dispatch(AddMinting({ formData }));

  //   let obj={
  //     multiple:false,
  //     platformType:"instagram",
  //     postUrl:"https://www.instagram.com/p/C8oPlFqMVfk/",
  //     userTokenID:"156",
  //     walletAddress:"0x8d94f0bfc6d0cce61ed2752b0d09cbc3fd20e38f"
  //   }

  //   dispatch(AddPostTest({obj,dispatch}))

  //   const postUrl = [
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //     "https://www.instagram.com/p/C8oPlFqMVfk/",
  //   ];

  //   let IgPosts = postUrl?.map((ele) => {
  //     return ele.split(".")[1];
  //   });

  //   const postMultipledetails = {
  //     userTokenID: "156",
  //     postUrl: postUrl,
  //     platformType: IgPosts,
  //     multiple: true,
  //     walletAddress:"0x8d94f0bfc6d0cce61ed2752b0d09cbc3fd20e38f"
  //   };
  //   dispatch(AddMultiplePostTest({ postMultipledetails, dispatch }));
  // }, []);

  const profilePictureUrl = profileDetails[0]?.profile_picture_url
    ? `${profileDetails[0]?.profile_picture_url}?t=${new Date().getTime()}`
    : "";

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Title className={showForm ? "text-center" : ""}>User Profile</Title>
      </div>
      {showForm && (
        <>

          <Button type="text" icon={<ArrowLeftOutlined />} onClick={OnBackButtonClick} style={{ marginBottom: "16px", fontSize: "15px" }}>
            Go Back
          </Button>
          <div className="profile-details">
            <Form form={form} name="basic" initialValues={{ remember: true }} layout="horizontal" className="form-size form_custom_css" autoComplete="off" onFinish={OnFinish}>
              <Form.Item
                label="Profile Picture"
                name="image"
                valuePropName="fileList"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.length === 0) {
                        if (!isProfilePictureUpdating) return Promise.resolve();
                        return Promise.reject(new Error("Please upload a file."));
                      }
                      const isValidFileType = value[0]?.type === "image/jpeg" || value[0]?.type === "image/png";
                      if (!isValidFileType) {
                        return Promise.reject(new Error("Only JPEG/PNG files are allowed."));
                      }
                      const isValidFileSize = value[0]?.size / 1024 / 1024 < 2;
                      if (!isValidFileSize) {
                        return Promise.reject(new Error("File size must be less than 2MB."));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                getValueFromEvent={(e) => {
                  if (e && e.fileList) {
                    return e.fileList;
                  }
                  return [];
                }}
              >
                <Upload name="image" listType="picture" maxCount={1} beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input your email" }]}
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Email" style={{ background: "#f5f7ff" }} readOnly defaultValue={formData?.email} value={formData?.email} />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number",
                  },
                  {
                    min: 10,
                    max: 15,
                    message: "Phone number should be between 10 and 15 digits",
                  },
                ]}
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
              >
                <PhoneInput defaultCountry="us" value={formData?.phone} />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please select your country" }]}
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
              >
                {/* <Input
                  placeholder="Enter your country"
                  defaultValue={formData?.country}
                  value={formData?.country}
                /> */}

                <CountryDropdown
                  classes="country_css"
                  style={{ background: "#f5f7ff" }}
                  value={country}
                  defaultOptionLabel={profileDetails[0]?.country ? profileDetails[0]?.country : "Please select your country"}
                  onChange={(val) => SelectCountry(val)}
                />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please select your city" }]}
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
              >
                {/* <Input
                  placeholder="Enter your city"
                  defaultValue={formData?.city}
                  value={formData?.city}
                /> */}

                <RegionDropdown
                  classes="city_css"
                  country={country}
                  value={region}
                  blankOptionLabel={profileDetails[0]?.city ? profileDetails[0]?.city : "Please select your city"}
                  defaultOptionLabel={profileDetails[0]?.city ? profileDetails[0]?.city : "Please select your city"}
                  onChange={(val) => SelectRegion(val)}
                />
              </Form.Item>

              <Form.Item
                label="Reference No"
                name="reference_number"
                rules={[
                  {
                    required: true,
                    message: "Please input your reference number",
                  },
                ]}
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
              >
                <Input
                  placeholder="Enter your reference number"
                  style={{ background: "#f5f7ff" }}
                  readOnly
                  defaultValue={formData?.reference_number}
                  value={formData?.reference_number}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: "0" }}>
                <Button type="primary" htmlType="submit" className="update-btn">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {!showForm && (
        <section className="profile-section">
          <div className="row">
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body custom-row-bk">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Image</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {" "}
                        <img className="rounded-circleProfile" src={profilePictureUrl ? profilePictureUrl : avatar} height={80} width={80} alt="Profile" />
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetails[0]?.email ? profileDetails[0]?.email : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mailto:mb-0">{profileDetails[0]?.phone ? profileDetails[0]?.phone : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Country</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetails[0]?.country ? profileDetails[0]?.country : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">City</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetails[0]?.city ? profileDetails[0]?.city : ""}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Reference number</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{profileDetails[0]?.reference_number ? profileDetails[0]?.reference_number : ""}</p>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">MetaMask PrivateKey</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{     privatekeyUser ? privatekeyUser : ""}</p>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-9" style={{ display: "flex", alignItems: "center" }}>
                      <p className="mb-0">
                        <button className="profile-edit" onClick={() => Toggle()}>
                          <span>Edit</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Profile;
